<template>
    <section class="mb-4" :class="{'d-inline-flex' : inline }">
        <label v-if="label"
            :for="'select-' + uid"
            class="form-label"
        >
            <span v-html="label"></span><Required v-if="isRequired" />
        </label>
        <select class="form-select"
            :class="{
                'disabled': ! options,
                'is-invalid': isRequired && v$.selected.$error
            }"
            :id="'select-'+uid"
            :disabled="! options"
            @change="$emit('update', $event.target.value)"
        >
            <option :value="null">
                {{ placeholder ?? 'Select an option' }}
            </option>
            <template v-if="options">
                <option v-for="option in options"
                    :value="option.value"
                    :selected="selected && selected == option.value ? true : false"
                >
                    {{ option.text }}
                </option>
            </template>
        </select>
    </section>
</template>

<script setup lang="ts">
import { helpers, requiredIf, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

const props = defineProps<{
    label?: string;
    selected: string|number|null;
    options: Array<string>|null;
    placeholder?: string;
    isRequired?: boolean;
    inline?: boolean;
}>();

const emit = defineEmits<{
    (event: 'update', value: string): void,
}>();

const uid = ref<string|null>(null);

onMounted(() => {
    uid.value = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
});

/**
* VALIDATION
*/
const rules = {
    selected: {requiredIf: helpers.withMessage('This field is required', requiredIf(() => props.isRequired))},
};
const v$ = useVuelidate(rules, props);
/** END VALIDATION **/
</script>
